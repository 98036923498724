import { calculateRemainingSplit } from "./calculateRemainingInvestment"

/**
 * Process rental listing information including financials, building details, and reservations.
 * @param {Object} listing - The rental listing object
 * @returns {Object} - The updated rental listing object with processed financial data
 */
export const processRentalListing = async listing => {
  // Extract financials, price, apartments, and reservations from the listing
  const financials = listing?.financials || {}
  const apartments = listing?.apartments || listing?.building?.apartments || []

  // Calculate initial purchase price and platform commission
  var purchasePriceFixed = parseFloat(
    listing?.startPrice || listing?.price?.startPrice || 0
  )
  const platformCommissionFixed = (purchasePriceFixed / 95) * 100 // 3% of purchase price for valvest commission
  purchasePriceFixed = platformCommissionFixed

  // Calculate initial purchase price and platform commission
  var purchasePrice = parseFloat(listing?.startPrice || 0)
  purchasePrice = (purchasePrice / 95) * 100 // 3% of purchase price for valvest commission
  purchasePrice =
    parseFloat(listing?.financials?.purchasePrice) || purchasePrice

  // Calculate fixed costs for various expenses
  const rentReturnFixed = await calculateFixedCost(apartments, "rentCost")
  const insuranceCostFixed = await calculateFixedCost(
    apartments,
    "insuranceCost"
  )

  const internetCostFixed = await calculateFixedCost(apartments, "internetCost")
  const managementCostFixed = await calculateFixedCost(
    apartments,
    "managementCost"
  )
  const cleaningCostFixed = await calculateFixedCost(apartments, "cleaningCost")
  const platformCostFixed = await calculateFixedCost(apartments, "platformCost")
  const garbageTaxFixed = await calculateFixedCost(apartments, "garbageTax")
  const propertyTaxFixed = await calculateFixedCost(apartments, "propertyTax")
  const utilityCostFixed = await calculateFixedCost(apartments, "utilityCost")

  // Extract or calculate various financial details
  const rentReturn = parseFloat(financials.rentReturn) || rentReturnFixed

  // Calculate fixed purchase costs
  const generatedPurchaseCostFixed =
    listing?.startPrice * 0.14 || listing?.price?.startPrice * 0.14

  const computedPurchaseCostFixed =
    (parseFloat(financials?.transferTaxCost) || 0) +
    (parseFloat(financials?.additionalPurchasingCost) || 0) +
    (parseFloat(financials?.agentCommissionCost) || 0)

  const purchaseCostFixed =
    computedPurchaseCostFixed > 0
      ? computedPurchaseCostFixed
      : generatedPurchaseCostFixed

  // Calculate variable purchase costs
  const generatedPurchaseCost =
    listing?.startPrice * 0.14 || listing?.price?.startPrice * 0.14
  const computedPurchaseCost =
    parseFloat(financials?.transferTaxCost || 0) +
    parseFloat(financials?.additionalPurchasingCost || 0) +
    parseFloat(financials?.agentCommissionCost || 0)

  const purchaseCost = listing?.financials?.purchaseCost
    ? parseFloat(listing?.financials?.purchaseCost)
    : computedPurchaseCost > 0
    ? computedPurchaseCost
    : generatedPurchaseCost

  // Calculate total investment amounts
  const investmentAmount = purchasePrice + purchaseCost
  const investmentAmountFixed = purchasePriceFixed + purchaseCostFixed
  const initialPurchasePrice =
    investmentAmountFixed -
    purchaseCostFixed -
    parseFloat(financials.VATPayable || 0)

  // Calculate fixed HOA cost
  const HOACostFixed =
    parseFloat(listing?.building?.HOA?.monthlyContribution) * 12 || 0

  // Calculate total monthly and exploitation costs
  const totalMonthlyCost =
    (parseFloat(cleaningCostFixed) + parseFloat(platformCostFixed)) * 12 +
    parseFloat(managementCostFixed)

  const totalExploitationCost =
    totalMonthlyCost +
    parseFloat(propertyTaxFixed) +
    parseFloat(garbageTaxFixed) +
    parseFloat(internetCostFixed) +
    parseFloat(utilityCostFixed) * 12 +
    insuranceCostFixed +
    HOACostFixed

  // Calculate BAR, NAR, and COC
  const BAR = ((rentReturnFixed * 12) / investmentAmountFixed) * 100

  const NAR =
    ((rentReturnFixed * 12 - totalExploitationCost) / investmentAmountFixed) *
    100

  // Calculate total bedrooms, bathrooms, and square meters
  let bedrooms = 0,
    bathrooms = 0,
    totalSquareMeter = 0
  apartments.forEach(apartment => {
    bedrooms += apartment.bedrooms || 0
    bathrooms += apartment.bathrooms || 0
    totalSquareMeter += apartment.squareMeter || 0
  })

  const details = {
    bedrooms,
    bathrooms,
    totalSquareMeter,
  }

  // Prepare financial data
  const financialsData = {
    expectedSellingPrice: parseFloat(financials.expectedSellingPrice) || 0,
    netHolidayRentCost: parseFloat(financials.netHolidayRentCost) || 0,
    purchasePrice,
    purchasePriceFixed,
    investmentAmount,
    loanAmount: 0,
    loanInterest: 0,
    purchaseCost,
    purchaseCostFixed,
    investmentAmountFixed,
    transferTaxCost: parseFloat(financials.transferTaxCost),
    additionalPurchasingCost: parseFloat(financials.additionalPurchasingCost),
    agentCommissionCost: parseFloat(financials.agentCommissionCost),
    projectManagementFee: parseFloat(financials.projectManagementFee),
    rentReturn,
    rentReturnFixed,
    rentIncrease: parseFloat(financials.rentIncrease) || 0.03,
    valueIncrease: parseFloat(financials.valueIncrease) || 0.02,
    inflation: parseFloat(financials.inflation) || 0.02,
    initialPurchasePrice,
    VATPayable: parseFloat(financials.VATPayable),
    purchaseCost,
    HOACost: HOACostFixed,
    BAR,
    NAR,
    totalExploitationCost,
    insuranceCostFixed,
    internetCostFixed,
    managementCostFixed,
    cleaningCostFixed,
    platformCostFixed,
    garbageTaxFixed,
    propertyTaxFixed,
    utilityCostFixed,
  }

  // Prepare updated listing object
  const NewListing = {
    ...listing,
    details,
    financials: financialsData,
    reservations: null,
  }

  return NewListing
}

/**
 * Calculate fixed cost for a specific expense type across all apartments
 * @param {Array} apartments - The array of apartments
 * @param {string} costType - The type of cost to calculate (e.g., rentCost

, insuranceCost)
 * @returns {number} - The total fixed cost for the given expense type
 */
const calculateFixedCost = async (apartments, costType) => {
  let fixedCost = 0
  if (fixedCost === 0 || fixedCost === "0") {
    await Promise?.all(
      apartments?.map(apartment => {
        const value = parseFloat(apartment?.[costType] || 0)
        fixedCost += value
      })
    )
  }
  return fixedCost
}
