import { calculateRemainingSplit } from "./calculateRemainingInvestment"

export const processFlipListing = async listing => {
  const financials = listing?.financials || {}
  const price = listing?.price || {}
  const apartments = listing?.apartments || listing?.building?.apartments || []

  var purchasePrice = parseFloat(price.startPrice || 0)

  const generatedPurchaseCost =
    parseFloat(financials.purchaseCost) || purchasePrice * 0.145
  const computedPurchaseCost =
    parseFloat(financials.transferTaxCost || 0) +
    parseFloat(financials.additionalPurchasingCost || 0) +
    parseFloat(financials.agentCommissionCost || 0)
  const purchaseCost =
    computedPurchaseCost > 0 ? computedPurchaseCost : generatedPurchaseCost
  const investmentAmount = parseFloat(price.startPrice || 0) + purchaseCost
  const initialPurchasePrice =
    purchasePrice -
    parseFloat(financials.VATPayable || 0) -
    listing?.building?.renovation?.cost

  const valvestCommissionFee = (purchasePrice / 95) * 100

  purchasePrice += purchaseCost

  const sellingProceeds =
    (parseFloat(financials.expectedSellingPrice) || 0) * 1.1 -
    purchasePrice -
    valvestCommissionFee -
    parseFloat(financials.projectManagementFee || 0)

  const returnFlip = (sellingProceeds / purchasePrice) * 100

  let bedrooms = 0,
    bathrooms = 0,
    totalSquareMeter = 0

  apartments.forEach(apartment => {
    bedrooms += apartment.bedrooms || 0
    bathrooms += apartment.bathrooms || 0
    totalSquareMeter += apartment.squareMeter || 0
  })

  const details = {
    bedrooms,
    bathrooms,
    totalSquareMeter,
  }

  const NewListing = {
    ...listing,
    financials: {
      expectedSellingPrice: parseFloat(financials.expectedSellingPrice) || 0,
      netHolidayRentCost: parseFloat(financials.netHolidayRentCost) || 0,
      purchasePrice,
      purchasePriceFixed: purchasePrice,
      investmentAmount,
      purchaseCost,
      purchaseCostFixed: purchaseCost,
      transferTaxCost: parseFloat(financials.transferTaxCost),
      additionalPurchasingCost: parseFloat(financials.additionalPurchasingCost),
      agentCommissionCost: parseFloat(financials.agentCommissionCost),
      projectManagementFee: parseFloat(financials.projectManagementFee),
      initialPurchasePrice,
      VATPayable: parseFloat(financials.VATPayable),
      sellingProceeds,
      return: returnFlip.toFixed(2),
      valvestCommissionFee,
    },
    reservations: null,
    details,
  }

  return NewListing
}
