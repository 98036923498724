// Footer.jsx
import Image from "next/image"
import React, { useState } from "react"
import valvestLogo from "@/public/logo_valvest.svg"
import styled from "styled-components"
import { Chip } from "@mui/material"
import { createListing } from "@/services/common/helpers"
import { useRouter } from "next/router"
import { useSession } from "next-auth/react"
import { ChevronDown, ChevronUp, Flag } from "react-bootstrap-icons"
import { FaRegEnvelope } from "react-icons/fa"
import translate from "@/utils/translation"
import { Link } from "next-translate-routes"
import TagManager from "react-gtm-module"
import Footernote from "./FooterNote/Footernote"
import LanguageDropdown from "./languageDropdown/LanguageDropdown"

// Reusable FooterSection Component
const FooterSection = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <SectionWrapper>
      <SectionHeader onClick={toggleOpen}>
        <h5>{title}</h5>
        <ToggleIcon>
          {isOpen ? <ChevronDown size={15} /> : <ChevronUp size={15} />}
        </ToggleIcon>
      </SectionHeader>
      <SectionList isOpen={isOpen}>{children}</SectionList>
    </SectionWrapper>
  )
}

const Footer = () => {
  const router = useRouter()
  const { data: session } = useSession()

  return (
    <Wrapper>
      <div className="container pt-2">
        <div className="row ps-3 pe-2 p-md-0">
          {/* Logo and Language Dropdown */}
          <div className="col-lg-3 col-md-6 col-12 mb-4 mb-md-0 p-0">
            <div className="w-100">
              <Image src={valvestLogo} width="175" alt="footer logo" />
            </div>
            <div className="ps-3">
              <LanguageDropdown />
            </div>
          </div>

          {/* Footer Sections */}
          <div className="col-lg-2 col-md-6 col-12 mt-lg-3 mt-2 mt-md-0">
            <FooterSection title={translate("footer_buy")}>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link href="/listings">{translate("footer_listings")}</Link>
                </li>
                <li>
                  <Link href="/auth/register">
                    {translate("menu_register")}
                  </Link>
                </li>
                {session && (
                  <>
                    <li>
                      <Link href="/account/balance">
                        {translate("account_submenu_balance")}
                      </Link>
                    </li>
                    <li>
                      <Link href="/account/portfolio">
                        {translate("account_submenu_portfolio")}
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </FooterSection>
          </div>

          <div className="col-lg-2 col-md-6 col-12 mt-lg-3 mt-2 mt-md-0">
            <FooterSection title={translate("footer_sell")}>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link href="/showcase">{translate("menu_showcase")}</Link>
                </li>
                <li>
                  <Link href="/#how-it-works">{translate("menu_how")}</Link>
                </li>
                <li>
                  <Link href="/blog">{translate("menu_blog")}</Link>
                </li>
                <li>
                  <Link href="/helpcenter">
                    {translate("footer_business_faq")}
                  </Link>
                </li>
                {(session?.accountType === "seller" ||
                  session?.accountType === "buyerAndSeller") && (
                  <li>
                    <a
                      className="clickable"
                      onClick={() => createListing(session, router)}
                    >
                      {translate("footer_sell_newObject")}
                    </a>
                  </li>
                )}
              </ul>
            </FooterSection>
          </div>

          <div className="col-lg-2 col-md-6 col-12 mt-lg-3 mt-2 mt-md-0">
            <FooterSection title={translate("footer_business")}>
              <ul className="list-unstyled mb-0">
                <li>
                  <Link href="/contact">
                    {translate("footer_business_contact")}
                  </Link>
                </li>
                <li>
                  <Link href="/about-us">
                    {translate("footer_business_about")}
                  </Link>
                </li>

                <li>
                  <Link href="/career">
                    {translate("footer_business_careers")}{" "}
                    <Chip
                      className="ms-1 rounded-pill"
                      label={translate("footer_business_new")}
                      color="info"
                      style={{
                        fontSize: "10px",
                        height: "20px",
                        width: "50px",
                      }}
                    />
                  </Link>
                </li>
              </ul>
            </FooterSection>
          </div>

          {/* Locations (No Dropdown Needed) */}
          <div className="col-lg-3 col-md-6 col-12 mt-5 mt-lg-3">
            <h5 className="">{translate("footer_locations")}</h5>
            <div className="address row">
              <div className="d-flex col-6  col-md-12">
                <div className="mt-2 d-none d-md-block">
                  <div className="icon">
                    <Flag size="12px" color={"#fb8500"} />
                  </div>
                </div>
                <div className="ms-md-3 mt-2">
                  <p className="fw-normal">
                    {translate("footer_locations_netherlands")}
                  </p>
                  <p>Keizersgracht 482</p>
                  <p>1017EG, Amsterdam</p>
                  <p>{translate("contact_widget_netherlands")}</p>
                </div>
              </div>
              <div className="d-flex mt-md-1 col-6 col-md-12 mt-md-3">
                <div className="mt-2 d-none d-md-block">
                  <div className="icon">
                    <Flag size="12px" color={"#fb8500"} />
                  </div>
                </div>
                <div className="ms-md-3 mt-2">
                  <p className="fw-normal">
                    {translate("footer_locations_spain")}
                  </p>
                  <p>Carrer de Colón 1</p>
                  <p>46004, València</p>
                  <p>{translate("contact_widget_spain")}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-0" />
        </div>
      </div>
      <Footernote session={session} />
    </Wrapper>
  )
}

export default Footer

// Styled Components
const Wrapper = styled.footer`
  padding: 2rem 0;
  background-color: white;

  h5 {
    font-size: 1rem;
  }

  ul {
    line-height: 2.25rem;
    li a {
      font-size: 0.8rem;
      text-decoration: none;
      color: #17233b;
      font-weight: 300;
    }
  }

  p {
    margin-top: 0;
    font-size: 0.8rem;
    font-weight: 300;
  }

  .signup {
    font-size: 12px;
    padding: 0.3rem 0.2rem;
  }

  .footer-note {
    font-size: 0.75rem;
    color: #8f8f8f;
    margin-top: 1.5rem;
  }

  .items {
    @media (max-width: 800px) {
      width: 100%;
      justify-content: center;
      padding-bottom: 1rem;

      .item {
        text-align: center;
      }
    }
  }

  .copyright {
    @media (max-width: 800px) {
      width: 100%;
      justify-content: center;
      margin-bottom: 1.5rem;
      text-align: center;
    }
  }

  .item {
    padding: 0 1rem;
    a {
      font-size: 0.75rem;
      text-decoration: none;
      color: #8f8f8f;
    }

    :first-child {
      padding-left: 0;
    }
  }

  .socials {
    a {
      color: #17233b;
    }
    gap: 20px;
    cursor: pointer;

    @media (max-width: 800px) {
      justify-content: center !important;
      width: 100%;
      text-align: center;
    }
  }

  .contact p {
  }

  .address {
    a {
      color: #17233b;
      text-decoration: none;
    }

    p {
      line-height: 1rem;
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    b {
      font-size: 1rem;
    }

    .icon {
      background-color: #fff0e0;
      padding: 1px 8px 3px;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }

  h5 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 400;
  }

  hr {
    margin-top: 3rem;
    background-color: #c3c3c3;
  }

  .email {
    border: 1px solid lightgray;
    padding: 0.4rem;
    border-radius: 50px;
    max-width: 210px;

    .icon {
      background-color: #fff0e0;

      svg {
        color: #fb8500;
      }

      padding: 1px 8px 3px;
      border-radius: 50%;
    }

    p {
      margin: 4px 0 0 0;

      a {
        padding: 0 20px;
        color: #17233b;
        text-decoration: none;
      }
    }
  }

  .disclaimer {
    font-size: 0.75rem;
    color: #8f8f8f;
    margin-top: 1.5rem;

    @media (max-width: 800px) {
      padding: 1rem;
    }
  }
`

const SectionWrapper = styled.div`
  width: 100%;
`

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  h5 {
    margin: 0;
    font-size: 1rem;
  }

  @media (min-width: 801px) {
    cursor: default;
  }
`

const ToggleIcon = styled.span`
  font-size: 1.2rem;
  display: block;

  @media (min-width: 801px) {
    display: none;
  }
`

const SectionList = styled.div`
  margin-top: 0.5rem;

  ul {
    display: ${({ isOpen }) => (isOpen ? "block" : "none")};

    @media (min-width: 801px) {
      display: block;
    }
  }
`
