import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(251, 133, 0)",
      contrastText: "#fff",
    },
  },

  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiPaper: {
      default: {
        disableElevation: true,
      },
    },
  },

  typography: {
    button: {
      textTransform: "none",
      fontSize: "1rem",
      textAlign: "center",
    },

    primary: {
      fontSize: "1rem",
    },

    fontFamily: [
      "Lexend",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),

    h1: {
      fontSize: "2.5rem",
      lineHeight: "2.5rem",
      fontWeight: "600",
    },

    h2: {
      fontSize: "1.7rem",
      lineHeight: "2rem",
      fontWeight: "600",
    },

    h3: {
      fontSize: "1.5rem",
      lineHeight: "1.75rem",
      fontWeight: "600",
    },

    h4: {
      fontSize: "1rem",
      lineHeight: "1.4rem",
      fontWeight: "600",
    },
  },
})
