import * as Sentry from "@sentry/nextjs"

function handleError(error, res) {
  console.error(error) // Log the error for debugging

  Sentry.captureException(error)

  if (res) {
    res.status(error.status || 500).json({
      message: error.message || "Internal Server Error",
    })
  }
}

export default handleError
