export const AppReducer = (state, action) => {
  //User manipilations
  switch (action.type) {
    //Listing relevant info

    case "init_stored":
      return {
        locale: action?.payload?.locale,
        id: Math.floor(Math.random() * 1000000000),
      }

    case "SET_LISTING_VIEW":
      return {
        ...state,
        listingView: action.payload,
      }

    case "SET_LISTING":
      return {
        ...state,
        listing: action.payload,
        loading: false,
      }

    case "SET_SHOW_MENU":
      return {
        ...state,
        showMenu: action.payload,
      }

    case "SET_LISTINGS":
      return {
        ...state,
        listings: action.payload,
        loading: false,
      }

    case "SET_TRANSACTION":
      return {
        ...state,
        transactionId: action.payload,
      }

    case "SET_FEEDBACK":
      return {
        ...state,
        feedback: {
          ...state.feedback,
          [action.payload.faq]: {
            feedback: action.payload.feedback,
          },
        },
      }

    case "SET_LISTINGS_FINISHED":
      return {
        ...state,
        listingsFinished: action.payload,
        loading: false,
      }

    case "SET_PROJECTS":
      return {
        ...state,
        projects: action.payload,
        loading: false,
      }

    case "SET_ACCOUNT_PROJECTS":
      return {
        ...state,
        account: {
          ...state.account,
          projects: action.payload,
        },
        loading: false,
      }

    case "SET_LISTINGS_FILTERS":
      return {
        ...state,
        listingFilters: action.payload,
      }

    case "SET_LANGUAGE":
      return {
        ...state,
        languages: action.payload,
      }

    case "SET_LOCALE":
      return {
        ...state,
        locale: action.payload,
      }

    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      }

    default:
      return state
  }
}
