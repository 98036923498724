import { Button, ClickAwayListener } from "@mui/material"
import { useSession } from "next-auth/react"
import { useRouter } from "next-translate-routes"
import React from "react"
import { MdNotificationsNone, MdOutlineInbox } from "react-icons/md"
import { IoCheckmarkDoneOutline } from "react-icons/io5"
import styled from "styled-components"
import { updateUser } from "@/lib/strapi/userServices"
import axios from "axios"
import { reloadSession } from "@/services/common/helpers"
import translate from "@/utils/translation"
import NotificationItem from "./notificationItem"

const NotificationDropdown = () => {
  const [showModal, setModal] = React.useState(false)
  const [visibleMessages, setVisibleMessages] = React.useState(3)
  const { data: session } = useSession()
  const router = useRouter()

  const showMoreMessages = () => {
    setVisibleMessages(prevCount => prevCount + 3)
  }

  const markAllAsRead = async () => {
    const unreadMessages =
      session.messages.filter(
        message => !session.readMessages?.includes(message.id)
      ) || []
    const readMessages =
      session.readMessages?.concat(unreadMessages.map(message => message.id)) ||
      []

    const body = {
      readMessages: readMessages,
    }

    await axios
      .put(`/api/users`, body, {
        headers: {
          Authorization: `bearer ${session.jwt}`,
        },
      })
      .catch(error => {
        console.log(error)
      })

    reloadSession()
  }

  const unreadNotifications = session.messages
    ? session.messages.filter(
        message =>
          !session.readMessages?.map(item => item.id).includes(message.id)
      )
    : []

  return (
    <Wrapper>
      <RoundButton
        onClick={() => setModal(!showModal)}
        aria-label="Toggle notifications"
        className={showModal ? "active " : ""}
      >
        {unreadNotifications.length > 0 && (
          <div className="unread-count">{unreadNotifications.length}</div>
        )}
        <MdNotificationsNone size="24" color="#151515" />
      </RoundButton>
      {showModal && (
        <ClickAwayListener onClickAway={() => setModal(false)}>
          <div className="messageBox">
            <div className="row header p-0 p-md-1">
              <div className="pt-2 d-flex justify-content-between">
                <h4>{translate("notifications_title")}</h4>
                {session.messages?.length > 0 && (
                  <div
                    className="clickable read mt-2"
                    onClick={() => markAllAsRead()}
                  >
                    <IoCheckmarkDoneOutline color="#008040" className="me-2" />
                    {translate("notifications_read")}
                  </div>
                )}
              </div>
              <hr className="p-0 m-0" />
            </div>
            <div className="row p-0">
              {session.messages
                ?.slice(0, visibleMessages)
                .map((message, index) => (
                  <NotificationItem message={message} session={session} />
                ))}
              {!session.messages && (
                <div className="p-4 text-center text">
                  No notifications found
                </div>
              )}
              <hr className="p-0 m-0" />
              {session.messages && visibleMessages < session.messages.length ? (
                <div className="mt-0 justify-content-between d-flex">
                  <Button onClick={showMoreMessages}>
                    {translate("notifications_show_more")}
                  </Button>
                  <Button
                    className="close"
                    onClick={() => {
                      setModal(false)
                    }}
                  >
                    {translate("notifications_close")}
                  </Button>
                </div>
              ) : (
                <div className="d-flex justify-content-end pt-2">
                  <Button
                    className="close"
                    onClick={() => {
                      setModal(false)
                    }}
                  >
                    {translate("notifications_close")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ClickAwayListener>
      )}
    </Wrapper>
  )
}

export default NotificationDropdown

const Wrapper = styled.div`
  padding: 0 0.5rem;
  margin: auto 1rem auto 0;
  position: relative;

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    width: 100%;
  }

  .messageBox {
    position: absolute;
    top: 2.75rem;
    max-height: 26rem;
    overflow-y: auto;
    right: 0.4rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 10px 0px #0000001a;
    padding: 0 1rem 0.75rem 1rem;
    z-index: 1;
    border: 1px solid #ededed;
    min-width: 25rem;

    @media (max-width: 768px) {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      max-height: 100%;
      width: 100%;
      min-width: 100%;
      border-radius: 0;
      padding: 0.75rem 0.5rem;
    }

    .header {
      position: sticky;
      top: 0;
      z-index: 2;
      background-color: white;
      padding-bottom: 0.5rem;
    }

    h4 {
      font-size: 1rem;
      margin-bottom: 0.5rem;

      @media (max-width: 768px) {
        text-align: center;
      }
    }

    hr {
      border: 1px solid #dbdbdb;
      margin: 0.5rem 0;
    }

    .close {
      color: #656565;
      font-size: 0.875rem;
      cursor: pointer;
    }

    .read {
      font-size: 0.875rem;
      color: #008040;
      cursor: pointer;

      @media (max-width: 768px) {
        display: block;
        text-align: center;
        margin-top: 0.5rem;
      }
    }

    .message {
      padding: 1rem 0.75rem;
      cursor: pointer;
      display: flex;
      align-items: center;

      @media (max-width: 768px) {
        padding: 0.75rem 0.5rem;
      }

      .w-18 {
        width: 18%;
        min-width: 40px; /* Ensure the icon area doesn't shrink too much on mobile */
      }

      .dot svg {
        width: 20px;
        height: 20px;
      }

      h5 {
        font-size: 0.875rem;
        line-height: 1;
        font-weight: 500;
        margin: 0.2rem 0 0.3rem;

        @media (max-width: 768px) {
          font-size: 0.85rem;
        }
      }

      p {
        font-size: 0.8rem;
        font-weight: 300;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 0.75rem;
        }
      }

      .avatar {
        background-color: white;
        border: 1px solid #ededed;
        box-shadow: 0px 0px 10px 0px #0000001a;
      }

      :hover {
        background-color: #e5f4ef;
        transition: background;
        border-radius: 5px;
      }

      .date {
        font-size: 0.75rem;
        color: #8a8a8a;
        margin: 0;

        @media (max-width: 768px) {
          font-size: 0.7rem;
        }
      }
    }

    .text {
      font-size: 0.875rem;
      color: #8a8a8a;

      @media (max-width: 768px) {
        font-size: 0.8rem;
      }
    }

    .show-more {
      font-size: 0.875rem;
      color: #007bff;
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
      &:hover {
        text-decoration: underline;
      }

      @media (max-width: 768px) {
        font-size: 0.85rem;
      }
    }
  }
`

const RoundButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  outline: none;

  &:focus {
    outline: 2px solid #007bff;
  }

  &:hover {
    background-color: #ffa50029;
    transition: background-color 0.3s;
  }

  &:not(:hover) {
    opacity: 0.8;
    transition: opacity 0.3s;
  }

  @media (max-width: 768px) {
    margin: 0.6rem 1rem 0 0;
  }

  .unread-count {
    background: red;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    color: white;
    font-size: 0.7rem;
    position: absolute;
    left: 10px;
    top: 1px;
  }
`
