import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`

html, body {
  font-family: Inter, sans-serif;
  font-size: 1rem;
  color:#17233b;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {

}

 small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: #8f8f8f;
  }

h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: @line-height-computed;
  margin-bottom: (@line-height-computed / 2);

  small,
  .small {
    font-size: 65%;
  }
}
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: (@line-height-computed / 2);
  margin-bottom: (@line-height-computed / 2);

  small,
  .small {
    font-size: 75%;
  }
}

h1, .h1{
  font-weight:600;
  font-size:3rem;
}

h2, .h2{
  font-weight:600;
  font-size:2.5rem;
}
h3, .h3{
  font-weight:600;
  font-size:2rem;
}

h4, .h4{
  font-weight:600;
  line-height:2.4rem;
  font-size:1.6rem;
}

h5, .h5{
  font-weight:600;
  font-size:.875rem;
}

.text-orange {
  color:#fb8500;
}



.btn-primary {
  background-color:#fb8500;
    border-radius: 4px;
    line-height: 40px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    padding: 0px 20px;
    box-sizing: border-box;
    transition: all 0.08s ease 0s;
    text-decoration: none;
    color: rgb(255, 255, 255);
    border: none;

    :active, :focus, :focus-visible{
      background-color:#fb8500;
      border:#fb8500 0px solid;
      box-shadow:none;
      outline-color:white;
    }
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color:#f7a13f;
    border-radius: 4px;
    min-height: 40px;
    line-height: 48px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    padding: 0px 20px;
    box-sizing: border-box;
    transition: all 0.08s ease 0s;
    text-decoration: none;
    color: rgb(255, 255, 255);
    border: none;
}

.MuiButtonBase-root{
  font-size:15px !important;
}

.alert-success {
  border:0px solid;
  border-radius:4px;
  padding:1.5rem;
}


.btn-primary:hover {
  background-color:#f7a13f;
  border:0px;
}

.btn:focus{
  box-shadow:none;
  border:0px;
}

.btn:hover{
  box-shadow:none;
  border:0px;
  color:"#eee !important"
}

.clickable {
  cursor:pointer;
}

.cookiewrapperbutton {
  margin: auto;
}

.css-qjbdbc-MuiFormLabel-root-MuiInputLabel-root{
  z-index:0;
}

.css-abpy60-MuiFormLabel-root-MuiInputLabel-root{
  z-index:0;
}

`

export default GlobalStyle
