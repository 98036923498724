import axios from "axios"
import { processRentalSplit } from "../listings/processRentalSplit"
import { processFlipListing } from "../listings/processFlip"
import { processRentalListing } from "../listings/processRental"
import translate from "../../utils/translation"
var crypto = require("crypto")

// Create a new listing
export const createListing = async (session, router) => {
  try {
    const response = await axios.post(`/api/listings/new`, router.locales, {
      headers: {
        Authorization: `bearer ${session.jwt}`,
      },
    })
    router.push(`/listings/${response.data.attributes.uid}/edit`)
  } catch (err) {
    console.log(err)
  }
}

// Delete a listing
export const deleteListing = async (id, session, router) => {
  try {
    const response = await axios.post(
      `/api/listings/delete/${id}`,
      {},
      {
        headers: {
          Authorization: `bearer ${session.jwt}`,
        },
      }
    )
    if (response.status === 200) {
      router.push(`/account/listings/`)
    }
  } catch (err) {
    console.log(err)
  }
}

// Calculate total investment
export const calculateTotalInvestment = listing => {
  let investmentAmount = parseFloat(listing?.price?.startPrice) || 0
  listing?.reservations?.forEach(reservation => {
    if (reservation.status === "paid") {
      investmentAmount += parseFloat(reservation.investmentAmount)
    }
  })
  return investmentAmount
}

// Update nested array
export const updateNestedArray = (array, index, update) => {
  const objectIndex = array.findIndex(item => item.index === index)
  if (objectIndex !== -1) {
    const updatedObject = { ...array[objectIndex], ...update }
    return [
      ...array.slice(0, objectIndex),
      updatedObject,
      ...array.slice(objectIndex + 1),
    ]
  }
  return array
}

// Generate neighborhood score
export const generateNeighbourhoodScore = score => {
  const stars = Math.floor(score)
  const hasHalfStar = score % 1 !== 0
  const starsArray = [...Array(stars)].map((_, index) => (
    <FontAwesomeIcon key={index} icon={faStar} color="orange" />
  ))
  if (hasHalfStar) {
    starsArray.push(
      <FontAwesomeIcon
        key={starsArray.length}
        icon={faStarHalf}
        color="orange"
      />
    )
  }
  return starsArray
}

// Process a listing
export const processListing = async listing => {
  if (listing?.objectType !== "flip" && !listing?.splitInvestment?.enabled) {
    return await processRentalListing(listing)
  } else if (
    listing?.objectType !== "flip" &&
    listing?.splitInvestment?.enabled
  ) {
    return await processRentalSplit(listing)
  } else if (listing.objectType === "flip") {
    return await processFlipListing(listing)
  }
}

//reload
export const reloadSession = () => {
  const event = new Event("visibilitychange")
  document.dispatchEvent(event)
}

export const highlightTranslator = highlight => {
  const translations = {
    "High profitability": "listing_single_highlight_high_profitability",
    "Discounted compared to the area":
      "listing_single_highlight_discounted_area",
    "Rental tension": "listing_single_highlight_rental_tension",
    Location: "listing_single_highlight_location",
    "Layout of the property": "listing_single_highlight_layout",
    "Bright apartment": "listing_single_highlight_bright_apartment",
    "Premium property": "listing_single_highlight_premium_property",
    "Good price": "listing_single_highlight_good_price",
    "Land valuation": "listing_single_highlight_land_valuation",
    // Add more translations as needed
  }

  const translatedHighlight = translations[highlight]

  if (translatedHighlight) {
    return translate(translatedHighlight)
  } else {
    // Handle case where the highlight doesn't match any translation
    return "Translation not found"
  }
}

export const fetchInvestments = async (
  setLoading,
  setReservations,
  session
) => {
  setLoading(true)

  const url = `/api/account/investments`

  const investmentResponse = await axios
    .get(url, {
      headers: {
        Authorization: `bearer ${session?.jwt}`,
      },
    })
    .catch(error => {
      console.log(error)
    })

  setReservations(investmentResponse.data)

  setLoading(false)
}

export const isVerified = session => {
  if (session?.verification?.status === "approved") {
    return true
  } else {
    return false
  }
}

function isSignatureValid({ signature, secret, payload }) {
  if (payload.constructor === Object) {
    payload = JSON.stringify(payload)
  }

  if (payload.constructor !== Buffer) {
    payload = Buffer.from(payload, "utf8")
  }

  const digest = crypto
    .createHmac("sha256", secret)
    .update(Buffer.from(payload, "utf8"))
    .digest("hex")
    .toLowerCase()

  return digest === signature.toLowerCase()
}

export const Dehash = req => {
  const signature = req.headers["x-hmac-signature"]
  const secret = process.env.VERIFF_KEY

  const payload = req.body

  return isSignatureValid({ signature, secret, payload })
}

export const roleDecider = role => {
  let translationKey

  switch (role) {
    case "privateEquity":
      translationKey = "account_my_profile_investorType_individual"
      break
    case "fundManager":
      translationKey = "account_my_profile_investorType_fundManager"
      break
    case "financeProfessional":
      translationKey = "account_my_profile_investorType_financialAdvisor"
      break
    case "entrepreneur":
      translationKey = "account_my_profile_investorType_entrepeneur"
      break
    default:
      translationKey = "account_my_profile_investorType_other"
  }

  return translationKey
}

export const checkProfileIsComplete = user => {
  const requiredFields = [
    "profile.firstName",
    "profile.phone",
    "profile.address.streetName",
    "profile.address.city",
    "profile.address.country",
    "profile.address.zipcode",
    "profile.dateOfBirth",
    "profile.role",
    "email",
  ]

  return requiredFields.every(field => {
    const value = field.split(".").reduce((o, k) => o?.[k], user)
    return value !== null && value !== undefined
  })
}
