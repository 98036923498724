// components/ErrorBoundary.js
import { Component } from "react"
import * as Sentry from "@sentry/nextjs"
import HeaderNavigation from "@/components/common/Layout/header/HeaderNavigation"
import styled from "styled-components"
import Footer from "@/components/common/Layout/footer/Footer"
import { Button } from "@mui/material"
import { MdHeartBroken } from "react-icons/md"
import { useRouter } from "next/router"
import translate from "@/utils/translation"
import { useEffect } from "react"

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo)

    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(error, { extra: errorInfo })
    }
  }

  render() {
    if (this?.state?.hasError) {
      const { fallback: FallbackComponent } = this.props

      if (FallbackComponent) {
        return <FallbackComponent error={this.state.error} />
      }

      const router = useRouter()

      const title = "Whoops! Error.."

      return (
        <>
          <Wrapper>
            <title>{title}</title>
            <HeaderNavigation />
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="text-center mt-5">
                    <MdHeartBroken size={94} color="grey" />
                    <h4 className="mt-4">Whoops, we messed up.</h4>
                    <p>
                      The error has been reported and will be fixed shortly.
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        router.back()
                      }}
                      className="mt-4 rounded-pill"
                    >
                      {translate("404_button")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
          <Footer />
        </>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
