/**
 * Calculate IRR rate based on provided parameters.
 * @param {number} investmentAmount - The total investment amount
 * @param {number} netRentalIncomePercentage - The net rental income percentage (after deducting all costs)
 * @param {number} investorShare - The investor's share in the selling proceeds
 * @param {number} holdDurationInYears - The hold duration in years
 * @param {number} yearlyValueIncrease - The yearly value increase percentage
 * @param {number} inflationRate - The inflation rate
 * @param {number} extraValueIncrease - Additional value increase resulting from renovation
 * @returns {number} - The calculated IRR rate
 */
export const calculateIRR = (
  investmentAmount,
  netRentalIncomePercentage,
  investorShare,
  holdDurationInYears,
  inflationRate,
  extraValueIncrease
) => {
  const cashFlows = [-investmentAmount]

  // Calculate cash flows for each year (excluding the initial investment)

  for (let i = 0; i < holdDurationInYears; i++) {
    // Calculate net rental income for each year
    const rentalIncome = netRentalIncomePercentage * investmentAmount

    // Adjust rental income for inflation
    const adjustedRentalIncome = rentalIncome * Math.pow(1 + inflationRate, i)

    // Add adjusted net rental income to cash flows

    if (i === holdDurationInYears - 1) {
      const finalValue =
        investmentAmount * Math.pow(1 + inflationRate, holdDurationInYears)
      const addition =
        extraValueIncrease * Math.pow(1 + inflationRate, holdDurationInYears)

      // Calculate investor's share in selling proceeds
      const total = (finalValue + addition - investmentAmount) * investorShare

      const adjustedTotal = investmentAmount + total + adjustedRentalIncome

      cashFlows.push(adjustedTotal)
    } else {
      cashFlows.push(adjustedRentalIncome)
    }
  }

  // Calculate IRR
  return IRR(cashFlows) * 100
}

/**
 * Calculate the Internal Rate of Return (IRR) based on provided cash flows.
 * @param {number[]} cashFlows - The array of cash flows over time.
 * @param {number} [iterations=100] - The number of iterations for the calculation (default is 100).
 * @returns {number|null} - The calculated IRR or null if not converging.
 */
const IRR = (cashFlows, iterations = 100) => {
  const tolerance = 0.00001
  let irr = 0

  for (let i = 0; i < iterations; i++) {
    // Calculate Net Present Value (NPV)
    const npv = cashFlows.reduce((acc, cf, index) => {
      return acc + cf / Math.pow(1 + irr, index)
    }, 0)

    // Calculate derivative of NPV with respect to IRR (NPV Prime)
    const npvPrime = cashFlows.reduce((acc, cf, index) => {
      return acc - (cf * index) / Math.pow(1 + irr, index + 1)
    }, 0)

    // Update IRR using Newton's method
    const newIRR = irr - npv / npvPrime

    // Check for convergence
    if (Math.abs(newIRR - irr) < tolerance) {
      return newIRR
    }

    // Update IRR for next iteration
    irr = newIRR
  }

  return null // If not converging
}
